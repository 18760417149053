export const ContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: '#FFFFFF',
  borderRadius: '8px',
  padding: '36px',
  margin: '32px auto',
  justifyContent: 'center',
  maxWidth: '776px',
}

export const HeaderContainerStyles = {
  textAlign: 'center',
  paddingBottom: '24px'
}

export const SubHeaderContainerStyles = {
  textAlign: 'center',
  paddingBottom: '32px'
}

export const InputStyles = {
  paddingBottom: '16px'
}

export const ActionBtnStyles = {
  width: '175px',
}

export const IconContainer = {
  paddingBottom: '24px'
}

export const BodyContent = {
  padding: '16px 0px'
}

export const LeftAlignedComponentBody = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start'
}

export const RegistrationContainerStyles = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px'
}

export const HelpAndFaqBtnStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  paddingRight: '16px'
}
