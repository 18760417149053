import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { AnchorButton } from '../elements/button.component';
import { useCustomerSupportNumber } from 'app/hooks/store/use-customer-support.hook';
import './customer-support.component.scss';

interface OwnProps {
  country?: CountryResponse;
  withIcon?: boolean;
  asLink?: boolean;
  asButton?: boolean;
}

export const CustomerSupportNumber = ({ country, withIcon, asLink, asButton }: OwnProps) => {
  const telNo = useCustomerSupportNumber(country);

  if (asLink) {
    return (
      <a className="js-phone-number" href={`tel:${telNo}`} data-testid="customer-support-number-as-link">
        {withIcon && <span className="glyphicon glyphicon-earphone space-outer-right-sm" />}
        {telNo}
      </a>
    );
  }

  if (asButton) {
    return (
      <AnchorButton href={`tel:${telNo}`} btnDefault className="js-phone-number" id="customer-support-number" data-testid="customer-support-number-as-button">
        <LocalPhoneOutlinedIcon />
      </AnchorButton>
    );
  }

  return (
    <span className="js-phone-number">
      {withIcon && <span className="glyphicon glyphicon-earphone space-outer-right-sm"  data-testid="customer-support-number-with-icon"/>}
      {telNo}
    </span>
  );
};
