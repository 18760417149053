import { openDB, DBSchema, IDBPDatabase } from 'idb';

const DB_NAME = 'carrier';
const STORE_NAME = 'main';
const DB_VERSION = 1;

/**
 * Schema for the IndexedDB.
 */
interface CarrierDB extends DBSchema {
  [STORE_NAME]: {
    key: string;
    value: { id: string; value: any };
  };
}

/**
 * IndexedDB wrapper class using idb.
 *
 * @example
 * Usage:
 * import IndexedDB from './indexedDB';
 * ...
 * await IndexedDB.addData(key, value);
 */
export class IndexedDB {
  private db: Promise<IDBPDatabase<CarrierDB>>;

  constructor() {
    this.db = this.initDB();
  }

  private async initDB(): Promise<IDBPDatabase<CarrierDB>> {
    return openDB<CarrierDB>(DB_NAME, DB_VERSION, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          db.createObjectStore(STORE_NAME, { keyPath: 'id' });
        }
      },
    });
  }

  public async addData(key: string, value: any): Promise<void> {
    const db = await this.db;
    await db.put(STORE_NAME, { id: key, value });
  }

  public async getData(key: string): Promise<any> {
    const db = await this.db;
    const result = await db.get(STORE_NAME, key);
    return result?.value;
  }

  public async deleteData(key: string): Promise<void> {
    const db = await this.db;
    await db.delete(STORE_NAME, key);
  }
}

export default new IndexedDB();
