import { Button } from '@chr/eds-react';
import Box from '@mui/material/Box';
import { push } from 'connected-react-router';
import { AppRoute } from 'app/routesEnum';
import { HelpAndFaqBtnStyles } from 'features/registration/styles/registration-shared-styles';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const HelpAndFaqButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Box sx={HelpAndFaqBtnStyles}>
      <Button
        buttonText={t('HELP_AND_FAQ')}
        variant='outlined'
        onClick={e => dispatch(push(AppRoute.OKTA_FAQ)) }
      />
    </Box>
  )
}
