import { useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { HelpAndFaqButton, RegistrationStage, RegistrationState } from 'features/registration';
import { useTranslation } from 'react-i18next';
import {
	ContainerStyles,
	HeaderContainerStyles,
	ActionBtnStyles,
	SubHeaderContainerStyles,
} from 'features/registration/styles/registration-shared-styles';
import { ConstructionBarrierIcon } from 'assets/icons';

type Props = {
  email: string;
  setRegistrationState: (state: RegistrationState) => void;
};

export const ErrorMessage = ({ email, setRegistrationState }: Props) => {
  const goBack = useCallback(() => setRegistrationState({ stage: RegistrationStage.EMAIL_VERIFICATION }), []);
  const { t } = useTranslation();

  return (
   <>
    <HelpAndFaqButton />
    <Box sx={ContainerStyles}>
     <Box sx={HeaderContainerStyles}>
      <ConstructionBarrierIcon alt={t('CONSTRUCTION_BARRIER_ICON')} />
     </Box>
     <Box sx={HeaderContainerStyles}>
      <Typography variant="h1">{t('SOMETHING_WENT_WRONG')}</Typography>
     </Box>
     <Box sx={SubHeaderContainerStyles}>
      <Typography variant="h4">{t('PREFERRED_EMAIL_ERROR_MESSAGE', { 0: email })}</Typography>
     </Box>
     <Button onClick={goBack} variant="contained" sx={ActionBtnStyles}>
      {t('PLEASE_TRY_AGAIN_NO_PERIOD')}
     </Button>
    </Box>
   </>
  );
}
