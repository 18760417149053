import { Inject, Service } from 'typedi';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { CacheableRepository } from 'app/repositories/cacheable.repository';
import { CaptchaStorageKeys, defaultCaptchaKey } from '@shared/components/turnstile-captcha/types';

@Service()
export class IdentityRepository extends CacheableRepository {
	@Inject('apiConfig.identityAPI')
	protected baseUrl;

	private registrationPrefix = '/registration';

	private emailVerificationPrefix = '/EmailVerification';

	private userPrefix = '/User';

	register = (registration: RegistrationRequest): Promise<null> => this.post(this.registrationPrefix, registration).toPromise();

  setHeaders = (key?: string) => {
    const captchaToken = localStorage.getItem(key ?? defaultCaptchaKey);
    const headers = { 'X-Captcha-Token': captchaToken};
    if(window.location.host.includes('.chr8s.io')){
      headers['X-IsEmphemeral'] = true;
    }
    return headers;
  }

  validateCarrierCode = (carrierCode: string): Promise<RegistrationValidationJSON> => {
    return this.get(`${this.registrationPrefix}/Validation/Carrier/${carrierCode.trim()}`, this.setHeaders(CaptchaStorageKeys.carrierCodeValidation)).toPromise();
  }

  sendNewUserEmailVerification = (emailAddress: string): Promise<RegistrationValidationJSON> => {
    return this.post(`${this.emailVerificationPrefix}/sendEmailVerification`, { emailAddress }, this.setHeaders(CaptchaStorageKeys.emailVerification)).toPromise();
  }

  sendExistingUserEmailVerification = (userId: number): Promise<void | RegistrationValidationJSON> => {
	  return this.post(`${this.emailVerificationPrefix}/sendEmailVerification`, { userId }, this.setHeaders(CaptchaStorageKeys.emailVerification)).toPromise();
  }

  newUserRegistration = (registration: RegistrationFormRequest): Promise<void> => {
    return this.post(this.registrationPrefix, registration, this.setHeaders(CaptchaStorageKeys.registrationSubmission)).toPromise();
  }

  verifyToken = (oneTimePassword: string, password: string): Promise<TokenVerificationJSON> => {
    return this.post(`${this.emailVerificationPrefix}/verifyEmail/${oneTimePassword}`, { password }, this.setHeaders(CaptchaStorageKeys.setExistingUserPassword)).toPromise();
  }

  updatePreferredOktaEmailAddress = (preferredEmailAddress: string): Promise<void> => {
    return this.post(`${this.userPrefix}/updatePreferredOktaEmailAddress`, { preferredEmailAddress }, this.setHeaders(CaptchaStorageKeys.updatePreferredEmail)).toPromise();
  }

  validateEmail = (email: string): Promise<void | Array<ValidationFailure>> => {
    return this.get(`${this.registrationPrefix}/Validation/EmailAddress/${email.trim()}`, this.setHeaders(CaptchaStorageKeys.emailValidation)).toPromise();
  }
}