import React, { MouseEventHandler } from 'react';
import { Button, CircularProgress, SxProps } from '@mui/material';
import { Translation } from 'shared/components/translation/translation.component';
import { FormButtonStyle, LoadingButtonStyle } from '../styles';
import { useTranslation } from 'react-i18next';

interface FormButtonProps {
  textResourceKey: ResourceKey,
  handleClick: Function,
  children?: React.ReactNode;
}

export const ConfirmFormButton = ({ textResourceKey, handleClick }: FormButtonProps) => {
  return (
    <Button
      sx={FormButtonStyle}
      size="large"
      variant="contained"
      onClick={handleClick as MouseEventHandler<HTMLButtonElement>}>
      <Translation resource={textResourceKey} />
    </Button>
  )
}

export const CancelFormButton = ({ textResourceKey, handleClick }: FormButtonProps) => {
  return (
    <Button
      sx={FormButtonStyle}
      size="large"
      variant="outlined"
      onClick={handleClick as MouseEventHandler<HTMLButtonElement>}>
      <Translation resource={textResourceKey} />
    </Button>
  )
}

interface LoadingButtonProps {
  loading: boolean,
  textResourceKey: ResourceKey | string,
  loadingStateTextResourceKey?: ResourceKey | string,
  handleClick?: Function,
  progressColor?: "inherit" | "error" | "primary" | "secondary" | "info" | "success" | "warning",
  children?: React.ReactNode;
  sx?: SxProps,
  type?: "button" | "submit" | "reset" | undefined,
}

export const LoadingButton = ({
  loading,
  textResourceKey,
  loadingStateTextResourceKey,
  handleClick,
  progressColor,
  sx = {},
  type = "button",
}: LoadingButtonProps) => {
    const sxProps: SxProps = { ...LoadingButtonStyle as SxProps, ...sx };
    const { t } = useTranslation();
  return (
    <Button
      sx={sxProps}
      size="large"
      variant="contained"
      disabled={loading}
      onClick={handleClick as MouseEventHandler<HTMLButtonElement>}
      type={type}
    >
      {!loading && t(textResourceKey)}
      {loading && !!loadingStateTextResourceKey && t(loadingStateTextResourceKey)}
      {loading && <CircularProgress color={progressColor} sx={{ padding: "5px", margin: "auto !important" }} />}
    </Button>
  )
}
