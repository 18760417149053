import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Typography from '@mui/material/Typography';
import * as Styles from 'features/registration/styles/form-styles';

interface Props {
  errorMessage: string;
}

export const EdsStyleErrorMessage = ({ errorMessage }: Props) => {
  return (
    <Typography variant="body2" sx={Styles.ErrorMessageStyles}>
      <ErrorOutlineIcon sx={Styles.ErrorIconStyles} />
      {errorMessage}
    </Typography>
  );
}
