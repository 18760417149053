import React from 'react';
import classNames from 'classnames';
import { SiteSectionContext } from 'shared/components/analytics/site-section.context';

interface Props {
  name: string;
  children: React.ReactNode;
}

export const SiteSection: React.FC<Props> = ({name, ...props}) => {
  const nameClass = name.toLowerCase().replace(/\s/g, '-') + '-section';
  return (
    <SiteSectionContext.Provider value={{section: name}}>
      <div className={classNames('site-section', nameClass)}>
        {props.children}
      </div>
    </SiteSectionContext.Provider>
  );
};
