import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { SelectChangeEvent } from '@mui/material/Select';
import { HelpAndFaqButton, ContainerStyles, InputStyles } from 'features/registration';
import { Container } from 'typedi';
import { Culture } from 'app/i18n/culture.actions';
import { useTranslation } from 'react-i18next';
import { ReferenceDataRepository } from 'app/repositories/reference-data.repository';
import { history } from 'store';
import { RegistrationAction, useAction } from '@features/datadog';

type Props = {
 culture?: Culture;
 onSubmit(culture: Culture): void;
};

type State = {
 country?: GlobalizationCountry;
 language?: GlobalizationCultureLanguage;
};

export const CultureSelect = ({ culture, onSubmit }: Props) => {
 const [selected, setSelected] = useState<State>({ country: culture.country, language: culture.locale });
 const [countries, setCountries] = useState<GlobalizationCountry[]>([]);
 const { t } = useTranslation();
 const track = useAction();

 useEffect(() => {
  const repo = Container.get(ReferenceDataRepository);
  repo.getGlobalizationLocales().subscribe(countriesResult => setCountries(countriesResult));
 }, []);

 const onCountryChange = (e: SelectChangeEvent<string>) => {
  const country = countries.find(c => c.name === e.target.value);
  setSelected({ ...selected, country, language: country?.locales[0] });
 };

 const onLanguageChange = (e: SelectChangeEvent<string>) => {
  const selectedLocale = selected.country?.locales.find(l => l.displayName === e.target.value);
  setSelected({ ...selected, language: selectedLocale });
 };

 const handleSubmit = () => {
  const { country, language } = selected;
  const culture = new Culture(country, language);
  onSubmit(culture);
  track(RegistrationAction.SetCulture, { culture });
 };

 const onCancel = () => history.push('/');

 return (
  <>
   <HelpAndFaqButton />
   <Box sx={ContainerStyles}>
    <Box sx={{ paddingBottom: '24px', textAlign: 'center' }}>
     <Typography variant="h1">{t('JOIN_NAVISPHERE_CARRIER')}</Typography>
    </Box>
    <Box sx={{ paddingBottom: '32px', maxWidth: '310px', textAlign: 'center' }}>
     <Typography variant="h5">{t('SELECT_COUNTRY_AND_LANGUAGE')}</Typography>
     <Typography variant="h5">{t('YOU_CAN_MODIFY_THESE_SETTINGS_LATER')}</Typography>
    </Box>
    <Box sx={InputStyles}>
     <FormControl fullWidth>
      <InputLabel id="country-label">{t('COUNTRY')}</InputLabel>
      <Select labelId="country-label" data-testid="culture-select" value={selected.country?.name || ''} onChange={onCountryChange}>
       {countries.map(c => (
        <MenuItem key={c.name} value={c.name}>
         {c.name}
        </MenuItem>
       ))}
      </Select>
     </FormControl>
    </Box>
    <Box sx={InputStyles}>
     <FormControl fullWidth>
      <InputLabel id="language-label">{t('LANGUAGE')}</InputLabel>
      <Select
       labelId="language-label"
       data-testid="culture-select"
       value={selected.language?.displayName || ''}
       onChange={onLanguageChange}
       disabled={selected.country?.locales.length === 1}
      >
       {selected.country?.locales.map(l => (
        <MenuItem value={l.displayName} key={l.locale}>
         {l.displayName}
        </MenuItem>
       ))}
      </Select>
     </FormControl>
    </Box>
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
     <Button onClick={onCancel} variant="outlined" sx={{ width: '160px', marginRight: '2px' }}>
      {t('CANCEL')}
     </Button>
     <Button onClick={handleSubmit} variant="contained" sx={{ width: '160px', marginLeft: '2px' }}>
      {t('NEXT')}
     </Button>
    </Box>
   </Box>
  </>
 );
};
