import { CSSProperties } from 'react';

export const JwtBanner = {
  padding: 20,
  margin: '0 auto',
  position: 'absolute',
  bottom: 50,
  left: 0,
  right: 0,
  height: 125,
  width: '80vw',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexGrow: 1,
} as CSSProperties

export const SSOLoginWrapper = {
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  width: '100%'
};

export const WrapperStyles = {
  minWidth: 100,
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-evenly',
  color: 'rgba(0,0,0,0.6)',
  fontStyle: 'italic',
};

export const TextLinkStyles = {
  color: '#0071C7',
  cursor: 'pointer',
  fontStyle: 'initial',
  transition: 'all 300ms ease-in',
  borderBottom: '0px solid',
  '&:hover': {
    borderBottom: '2px solid'
  }
};

export const EmailAddressEntryStyle = {
  textAlign: 'left' as 'left',
  mt: 6,
}

const PasswordEntryBaseStyle = {
  textAlign: 'left' as 'left',
}

export const PasswordEntryStyle = {
  ...PasswordEntryBaseStyle,
  mt: 6,
  mb: 3
}

export const ConfirmPasswordEntryStyle = {
  ...PasswordEntryBaseStyle,
}

export const PreferredEmailContentBoxStyle = {
  width: '100%',
}

export const FormButtonStyle = {
  textTransform: 'none',
  m: 2,
  minWidth: 130,
  minHeight: 50
}

export const LoadingButtonStyle = {
  textTransform: 'none',
  m: 2,
  width: "fit-content",
  minWidth: 130,
  minHeight: 50
}

export const PreferredEmailH1 = {
  fontSize: 24,
  fontWeight: 400,
}

export const PreferredEmailH2 = {
  fontSize: 16,
  fontWeight: 400,
}

export const PreferredEmailPasswordRequirements = {
  textAlign: 'left' as 'left',
}

export const PreferredEmailPasswordRequirementsChildElements = {
  textAlign: 'left' as 'left',
}

export const PreferredEmailPasswordRequirementsHeader = {
  display: 'flex',
  marginBottom: 10,
}

export const PreferredEmailPasswordRequirementsBox = {
  backgroundColor: '#F1F8FF',
  padding: 24,
  borderRadius: 6,
  marginTop: 48,
}

export const PreferredEmailAnimation = {
  transformOrigin: '0 0 0'
}

export const PreferredEmailFAQBox = {
  display: 'flex',
  flexDirection: 'row-reverse'
}

export const PreferredLearnMoreLink = {
  marginLeft: 5,
}

export const PreferredEmailMessageText = {
  fontSize: 16,
}

export const ButtonStackStyle = {
  marginTop: 32,
}

export const PreferredEmailExistingUserSuccessHeaderStyle = {
  fontSize: 24,
  marginBottom: 32,
}

export const PreferredEmailIconStyle = {
  marginTop: 32,
  marginBottom: 32,
}

export const PreferredEmailExistingUserSuccessMessageStyle = {
  fontSize: 16,
  marginTop: 32,
  marginBottom: 36,
}


export const PreferredEmailNewUserSuccessHeaderStyle = {
  fontSize: 24,
  marginBottom: 8,
}

export const PreferredEmailNewUserSuccessSubHeaderStyle = {
  fontSize: 16,
  marginBottom: 30,
}

export const PreferredEmailNewUserSuccessMessage1Style = {
  fontSize : 16,
  marginTop: 32,
}

export const PreferredEmailNewUserSuccessMessage2Style = {
  fontSize : 16,
}

export const PreferredEmailMessageTextStyle = {
  marginTop: 80,
  padding: '45px 84px',
  border: '1px solid #ADADAD',
  maxWidth: '110ch',
  textAlign: 'center' as 'center'
}

export const PreferredEmailErrorTextStyle = {
  lineHeight: '24px',
  marginBottom: 32,
}

export const PreferredEmailLoadingButtonStyle = {
  margin: 0,
  py: 0.5,
  px: 2,
}
