import { ICarrierSvg } from '.';

export const ConstructionBarrierIcon = ({ className, alt, width = 150, height, ...rest }: ICarrierSvg) => {
 return (
  <svg {...{ className, alt, width, height, ...rest }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
   <path fill="#5cb3e4" d="M78.6 52.6v24H21.5v-24h-5.9v47.2h5.9V82.5h57.1v17.3h5.8V52.6z" />
   <path fill="#96c7e1" d="M.9 50.7h98.2v.2c0 1-.8 1.8-1.8 1.8H2.7c-1 0-1.8-.8-1.8-1.8v-.2z" />
   <path
    fill="#d2e9f7"
    d="M17.4 11.7c.3-1 0-2.1-.7-2.8L13.9 6c-1.1-1-2.9-1-4 .1-.1.1-.2.2-.3.4-.8 1.1-.6 2.7.4 3.6l2.7 2.7c1.1 1.1 2.8 1.2 4 .2.3-.4.6-.8.7-1.3M14.8 18c-.5-.9-1.4-1.5-2.5-1.5h-4c-1.5 0-2.8 1.3-2.8 2.8v.5c.3 1.4 1.5 2.3 2.9 2.3h3.8c1.4 0 2.6-.9 2.9-2.3 0-.6-.1-1.2-.3-1.8M24 9.4C24.9 9 25.5 8 25.5 7V3c0-1.6-1.3-2.8-2.8-2.8h-.5c-1.4.3-2.3 1.5-2.3 2.9v3.8c0 1.4.9 2.6 2.3 2.9.6 0 1.2-.1 1.8-.4M33.4 32.2l-6.2 18.5h13.1l6.2-18.5zM59.6 32.2l-6.2 18.5h13.1l6.2-18.5zM7.2 32.2L1 50.7h13.1l6.2-18.5zM97.2 32.2H85.8l-6.2 18.5h13.1l6-17.8c-.4-.5-.9-.7-1.5-.7z"
   />
   <path
    fill="#a9d5f1"
    d="M2.9 32.2c-1.1 0-1.9.9-1.9 1.9v16.5l6.2-18.5H2.9zM20.3 32.2l-6.2 18.5h13.1l6.2-18.5zM46.5 32.2l-6.2 18.5h13.1l6.2-18.5zM72.7 32.2l-6.2 18.5h13.1l6.2-18.5zM99.1 34.2c0-.5-.2-.9-.5-1.2l-6 17.8H99V34.2zM25 27.2c4 0 7.3-3.3 7.3-7.3S29 12.6 25 12.6s-7.3 3.3-7.3 7.3 3.3 7.3 7.3 7.3zM75.1 27.2c4 0 7.3-3.3 7.3-7.3s-3.3-7.3-7.3-7.3-7.3 3.3-7.3 7.3c.1 4 3.3 7.3 7.3 7.3z"
   />
  </svg>
 );
};
