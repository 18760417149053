import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import {
	ContainerStyles,
	HeaderContainerStyles,
	SubHeaderContainerStyles,
	HelpAndFaqButton,
	RegistrationStage,
	RegistrationState,
} from 'features/registration';
import { AppRoute } from "@app/routesEnum";
import { history } from "store";

type Props = {
  setRegistrationState: (state: RegistrationState) => void;
};

export const EmailTakenError = ({ setRegistrationState }: Props) => {
  const goBack = () => setRegistrationState({ stage: RegistrationStage.EMAIL_VERIFICATION });
  const goToLogin = () => history.push(AppRoute.LOGIN);
    const { t } = useTranslation();

  return (
    <>
      <HelpAndFaqButton />
      <Box sx={ContainerStyles}>
        <Box sx={HeaderContainerStyles}>
          <img src='/assets/images/construction-barrier.svg' alt={t("CONSTRUCTION_BARRIER_ICON")}  />
        </Box>
        <Box sx={HeaderContainerStyles}>
          <Typography variant="h1">
            {t("EMAIL_ALREADY_REGISTERED")}
          </Typography>
        </Box>
        <Box sx={SubHeaderContainerStyles}>
          <Typography variant="h4">
            {t("LOG_IN_TO_YOUR_EXISTING_ACCOUNT")}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={goBack}
            variant="outlined"
            sx={{ width: '160px', marginRight: '2px' }}
          >
            {t("GO_BACK")}
          </Button>
          <Button
            onClick={goToLogin}
            variant="contained"
            sx={{ width: '160px', marginLeft: '2px' }}
          >
            {t("LOG_IN")}
          </Button>
        </Box>
      </Box>
    </>
  );
};
