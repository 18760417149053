import { useState } from 'react';
import { isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { useGlobalizationLocales } from '@app/hooks/use-globalization-locales';
import { useTranslation } from 'react-i18next';
import { Container } from 'typedi';
import { IdentityRepository } from 'app/repositories/identity.repository';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { UserValidationReason } from 'shared/enums/user/validation-reason.enum';
import { CarrierCodePositionValues, VendorCodePositionValues, RegistrationStage } from 'features/registration';

export enum PhoneNumberType {
  MOBILE = 0,
  BUSINESS = 1
}

export enum CarrierCodeType {
  T_CODE = 0,
  V_CODE = 1
}

export enum FormFields {
  CARRIER_CODE_TYPE = 'carrierCodeType',
  CARRIER_CODE = 'carrierCode',
  COMPANY_NAME = 'companyName',
  POSITION = 'position',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE_NUMBER = 'phoneNumber',
  PHONE_NUMBER_TYPE = 'phoneNumberType',
  PROMOTIONAL_CONSENT = 'promotionalConsent'
}

export const useRegistrationForm = (initialState, setRegistrationState, setWasCarrierCodeValidationPerformed) => {
  const countries = useGlobalizationLocales();
  const [values, setValues] = useState<RegistrationFormValues>(initialState);
  const [errors, setErrors] = useState<RegistrationFormErrors>({});
  const { t } = useTranslation();
  const [isCarrierCodeFocused, setIsCarrierCodeFocused] = useState(false);

  const validatedFields = [
    'carrierCode',
    'companyName',
    'position',
    'firstName',
    'lastName',
    'phoneNumber'
  ];

  const isTCode = values.carrierCodeType === CarrierCodeType.T_CODE;
  const carrierCodeTypeString = isTCode ? 'T' : 'V';

  const handleFieldChange = (fieldName: string) => (x: any) => {
    let value: any;
    switch (fieldName) {
      case FormFields.PHONE_NUMBER:
        value = x;
        break;
      case FormFields.PHONE_NUMBER_TYPE:
        value = Number(x.target.value);
        break;
      case FormFields.CARRIER_CODE_TYPE:
        value = Number(x.target.value);
        handleCarrierCodeTypeChange(value);
        break;
      case FormFields.PROMOTIONAL_CONSENT:
        value = x.target.checked;
        break;
      case FormFields.CARRIER_CODE:
        setIsCarrierCodeFocused(true);
        value = x.target.value;
        break;
      default:
        value = x.target.value;
        break;
    }

    setValues((previousValues) => {
      return {
        ...previousValues,
        [fieldName]: value
      }
    });

    // clear out form errors for company name and carrier code if switching to driver position as these fields will not be visible anymore
    if (fieldName === FormFields.POSITION && value == CarrierCodePositionValues.DRIVER_LCASE) {
      setErrors((previousErrors) => {
        return {
          ...previousErrors,
          [FormFields.COMPANY_NAME]: '',
          [FormFields.CARRIER_CODE]: ''
        }
      });
    }

    // other fields remove error onBlur but the radio group doesn't have that so we should remove error when a value is selected
    if (fieldName === FormFields.POSITION) {
      setErrors((previousErrors) => {
        return {
          ...previousErrors,
          [fieldName]: ''
        }
      });
    }
  };

  const validateField = (fieldName: string) => {
    let value = values[fieldName];
    switch (fieldName) {
      case FormFields.PHONE_NUMBER:
        validatePhoneNumber(value);
        break;
      case FormFields.CARRIER_CODE:
        validatecarrierCode(value);
        break;
      default:
        validateDefault(fieldName, value);
    }
  }
  const handleOnBlur = (fieldName: string) => () => {
    validateField(fieldName);
  }

  const validatecarrierCode = (value: string) => {
    // Drivers will not have a carrier code value
    setIsCarrierCodeFocused(false);
    if (values['position'] === CarrierCodePositionValues.DRIVER_LCASE) return;
    if (value === '') {
      setErrors((previousErrors) => {
        return {
          ...previousErrors,
          carrierCode: isTCode ? t('MISSING_TCODE') : t('MISSING_VCODE')
        }
      });
    } else {
      value = carrierCodeTypeString + value;
      setWasCarrierCodeValidationPerformed(true)
      Container.get(IdentityRepository).validateCarrierCode(value)
        .then(response => handlecarrierCodeSuccess(response, value))
        .catch(error => handlecarrierCodeError(error))
    }
  }

  const handlecarrierCodeSuccess = (response: RegistrationValidationJSON, carrierCode: string) => {
    if (!response.isValid) {
      switch (response.reason) {
        case UserValidationReason.CarrierWithCodeDoesNotExist:
          setErrors((previousErrors) => {
            return {
              ...previousErrors,
              carrierCode: t('NO_CARRIER_EXISTS', { 0: carrierCode })
            }
          });
          break;
        case UserValidationReason.InactiveCarrierCode:
          setErrors((previousErrors) => {
            return {
              ...previousErrors,
              carrierCode: t('CARRIER_IS_NOT_IN_ACTIVE_STATUS', { 0: carrierCode })
            }
          });
          break;
        case UserValidationReason.VendorWithCodeDoesNotExist:
          setErrors((previousErrors) => {
            return {
              ...previousErrors,
              carrierCode: t('NO_VENDOR_EXISTS', { 0: carrierCode })
            }
          });
          break;
        case UserValidationReason.InactiveVendorCode:
          setErrors((previousErrors) => {
            return {
              ...previousErrors,
              carrierCode: t('VENDOR_IS_NOT_IN_ACTIVE_STATUS', { 0: carrierCode })
            }
          });
          break;
        default:
          setErrors((previousErrors) => {
            return {
              ...previousErrors,
              carrierCode: isTCode ? t('MISSING_TCODE') : t('MISSING_VCODE')
            }
          });
      }
    } else {
      setErrors((previousErrors) => {
        return {
          ...previousErrors,
          [FormFields.CARRIER_CODE]: ''
        }
      });
    }
  }

  const handlecarrierCodeError = (error: AjaxError) => {
    setErrors((previousErrors) => {
      return {
        ...previousErrors,
        carrierCode: t('UNABLE_TO_VALIDATE_CARRIER_CODE')
      }
    });
  };

  const validateDefault = (fieldName: string, value: string) => {
    // Drivers will not have a company name to validate
    if (fieldName === FormFields.COMPANY_NAME && values['position'] === CarrierCodePositionValues.DRIVER_LCASE) return;
    if (!value) {
      setErrors((previousErrors) => {
        return {
          ...previousErrors,
          [fieldName]: t(`MISSING_${fieldName.toUpperCase()}`)
        }
      });
    } else {
      setErrors((previousErrors) => {
        return {
          ...previousErrors,
          [fieldName]: ''
        }
      });
    }
  }

  const validatePhoneNumber = (value: string) => {
    if (!isPossiblePhoneNumber(value)) {
      setErrors((previousErrors) => {
        return {
          ...previousErrors,
          phoneNumber: t('INVALID_PHONE_NUMBER')
        }
      });
    } else {
      setErrors((previousErrors) => {
        return {
          ...previousErrors,
          [FormFields.PHONE_NUMBER]: ''
        }
      });
    }
  }

  const getPhoneNumber = () => {
    const result = parsePhoneNumber(values.phoneNumber);
    const phoneNumberWithoutDialingCode = result?.nationalNumber;
    const iso3166Alpha3Code = countries.find(c => c.code === result?.country)?.iso3166Alpha3Code ?? '';
    return {
      number: phoneNumberWithoutDialingCode,
      type: values.phoneNumberType,
      countryCodeISO3166Alpha3: iso3166Alpha3Code
    };
  }

  const handleCarrierCodeTypeChange = (carrierCodeType: CarrierCodeType) => {
    const positionValue = carrierCodeType === CarrierCodeType.T_CODE ? '' : VendorCodePositionValues.ACCOUNTS_RECEIVABLE;

    setValues((previousValues) => {
      return {
        ...previousValues,
        position: positionValue
      }
    });

    setErrors((previousErrors) => {
      return {
        ...previousErrors,
        position: ''
      };
    });
  }

  const handleSubmission = () => {
    // validate all fields before submission
    validatedFields.forEach(field => validateField(field));
    const isDriver = values['position'] === CarrierCodePositionValues.DRIVER_LCASE;
    const isOwnerOperator = values['position'] === CarrierCodePositionValues.OWNER_OPERATOR;

    // wait for error state to update before checking if any are present to block form submission
    setErrors((previousErrors) => {
      const formErrors = Object.values(previousErrors).some((value) => value.trim());
      if (!formErrors) {
        const registrationRequestBody = {
          oneTimePasswordValue: values.otp,
          promotionalConsent: values.promotionalConsent,
          carrierCode: isDriver ? 'TMPCARRIER' : carrierCodeTypeString + values.carrierCode,
          companyName: isDriver ? 'Limited Driver' : values.companyName,
          position: isOwnerOperator ? CarrierCodePositionValues.MANAGER : values.position,
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: getPhoneNumber(),
        }
        Container.get(IdentityRepository).newUserRegistration(registrationRequestBody)
          .then(() => setRegistrationState({ stage: RegistrationStage.REGISTRATION_COMPLETE }))
          .catch(() => setRegistrationState({ stage: RegistrationStage.REGISTRATION_ERROR }));
      }
      return previousErrors;
    });
  }

  return {
    values,
    errors,
    handleFieldChange,
    handleOnBlur,
    handleSubmission,
    isCarrierCodeFocused
  };
};
