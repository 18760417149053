import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { AnchorButton } from '../elements/button.component';
import { useCustomerSupportEmail } from 'app/hooks/store/use-customer-support.hook';

interface OwnProps {
  country?: CountryResponse;
  withIcon?: boolean;
  asButton?: boolean;
  className?: string;
}

export const CustomerSupportEmail = ({ country, withIcon, asButton, className = null }: OwnProps) => {
  const email = useCustomerSupportEmail(country);

  if (asButton) {
    return (
      <AnchorButton href={`mailto:${email}`} btnDefault id="customer-support-email" data-testid="customer-support-email-as-button">
        <EmailOutlinedIcon />
      </AnchorButton>
    );
  }

  return (
    <a href={`mailto:${email}`} className={className}>
      {withIcon && <span className="glyphicon glyphicon-envelope space-outer-right-sm"  data-testid="customer-support-email-with-icon"/>}
      {email}
    </a>
  );
};