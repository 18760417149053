import { lazy } from 'react';
import RegistrationRoutes from './routes';
import { Culture } from '@app/i18n/culture.actions';

export * from './components';
export * from './styles';
export * from './utils';
export * from './forms';
export * from './hooks';

const LazyRegistrationPageV2 = lazy(() =>
	import(/* webpackChunkName: "registration-v2", webpackPrefetch: true */ 'features/registration/components/RegistrationV2').then(
		({ RegistrationPageV2 }) => ({
			default: RegistrationPageV2,
		})
	)
);

export enum RegistrationStage {
	EMAIL_VERIFICATION = 'email-verification',
	VERIFICATION_SENT = 'verification-sent',
	ERROR = 'error',
	REGISTRATION_ERROR = 'registration-error',
	EMAIL_TAKEN = 'email-taken',
	VERIFICATION_RECIEVED = 'verification-received',
	SET_CULTURE = 'set_culture',
	REGISTRATION_FORM = 'registration-form',
	REGISTRATION_COMPLETE = 'registration-complete',
}

export type RegistrationState = {
	stage?: RegistrationStage;
	otp?: string;
	email?: string;
	culture?: Culture;
};

export { LazyRegistrationPageV2, RegistrationRoutes };