export enum UserValidationReason
{
  UsernameInUse = 0,
  InvalidCarrierCode = 1,
  InvalidCarrierCodeFirstNameLastNameCombination = 2,
  CarrierWithCodeDoesNotExist = 3,
  EmailAddressTaken = 4,
  EmailAddressDoesNotExist = 5,
  UsernameDoesNotExist = 6,
  InvalidUsernameLength = 7,
  InvalidUsernameChars = 8,
  InvalidUsernameContainsPassword = 9,
  InvalidPasswordLength = 10,
  InvalidPasswordChars = 11,
  InvalidPasswordLetterNumber = 12,
  InvalidPasswordContainsUsername = 13,
  InvalidInformation = 14,
  AlreadyRegistered = 15,
  InactiveCarrierCode = 16,
  VendorWithCodeDoesNotExist = 17,
  InactiveVendorCode = 18,
  InvalidVendorCode = 19,
}
