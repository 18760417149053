import { ReferenceDataRepository } from '@app/repositories/reference-data.repository';
import { useEffect, useState } from 'react'
import Container from 'typedi';

export const useGlobalizationLocales = () => {
    const [countries, setCountries] = useState<GlobalizationCountry[]>([]);

    useEffect(() => {
        Container
            .get(ReferenceDataRepository)
            .getGlobalizationLocales()
            .subscribe((countriesResult) => setCountries(countriesResult));
    }, [])

    return countries;
}
