import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

import { EmailVerificationIcon } from '@features/okta/components/RegistrationEmailVerification/EmailVerificationIcon';
import {
	ContainerStyles,
	HeaderContainerStyles,
	ActionBtnStyles,
	SubHeaderContainerStyles,
	HelpAndFaqButton,
	RegistrationStage,
	RegistrationState,
} from 'features/registration';

type Props = {
  setRegistrationState: (state: RegistrationState) => void;
};

export const VerificationReceived = ({ setRegistrationState }: Props) => {
  const handleNextClicked = () => setRegistrationState({ stage: RegistrationStage.SET_CULTURE });
  const { t } = useTranslation();

  return (
    <>
      <HelpAndFaqButton />
      <Box sx={ContainerStyles}>
        <Box sx={HeaderContainerStyles}>
          <EmailVerificationIcon altText={t("OKTA_EMAIL_VERIFICATION_ICON_ALT_TEXT")} />
        </Box>
        <Box sx={HeaderContainerStyles}>
          <Typography variant="h1">
                      {t("YOUR_EMAIL_WAS_VERIFIED_SUCCESSFULLY")}
          </Typography>
        </Box>
        <Box sx={SubHeaderContainerStyles}>
          <Typography variant="h4">
                      {t("CONTINUE_TO_COMPLETE_YOUR_REGISTRATION")}
          </Typography>
        </Box>
        <Button
          onClick={handleNextClicked}
          variant="contained"
          sx={ActionBtnStyles}
        >
                  {t("NEXT")}
        </Button>
      </Box>
    </>
  );
};
