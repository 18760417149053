import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
	EmailVerification,
	CultureSelect,
	ErrorMessage,
	VerificationSent,
	RegistrationComplete,
	VerificationReceived,
	RegistrationFormComponent,
	RegistrationErrorMessage,
	EmailTakenError,
	RegistrationContainerStyles,
	RegistrationStage,
	RegistrationState,
} from 'features/registration';

import { useSelector } from '@app/hooks/store/use-selector.hook';
import { Culture, saveCulture } from '@app/i18n/culture.actions';
import { useDispatcher } from '@app/hooks/store/use-dispatcher.hook';

import 'features/let-body-fill-page.scss';

export const RegistrationPageV2 = () => {
  const save = useDispatcher(saveCulture);
  const culture: Culture = useSelector(state => state.culture);
  const [registrationState, setRegistrationState] = useState<RegistrationState>(() => {
    const search = window.location.search;
    const state = { stage: RegistrationStage.EMAIL_VERIFICATION, otp: '', email: '' };
    if (!search) return state;
    const urlSearchParams = new URLSearchParams(search);
    Object.keys(state).forEach(k => {
      const value = urlSearchParams.get(k);
      if (value) state[k] = value;
    })

    return state as RegistrationState;
  });

  useEffect(() => {
    // Pull queryParams from current url then process ours
    const searchParams = new URLSearchParams(window.location.search);

    // Find our specific params and set them
    Object.entries(registrationState)
      .filter(([, value]) => !!value) // Corrected syntax
      .forEach(([key, value]) => searchParams.set(key, value.toString()));

    window.history.replaceState(null, '', `${window.location.pathname}?${searchParams.toString()}`);
  }, [registrationState]);

  useEffect(() => {
    // css class needed to limit styles from impacting other pages
    const cssClass = 'let-body-fill-page';
    document.body.classList.add(cssClass);
    return () => {
      document.body.classList.remove(cssClass);
    };
  }, []);

  const handleSaveCulture = (culture: Culture) => {
    save(culture);
    setRegistrationState({ ...registrationState, culture, stage: RegistrationStage.REGISTRATION_FORM } as RegistrationState);
  };

  const isEmailVerification = registrationState.stage === RegistrationStage.EMAIL_VERIFICATION;
  const isVerificationSent = registrationState.stage === RegistrationStage.VERIFICATION_SENT;
  const hasError = registrationState.stage === RegistrationStage.ERROR;
  const hasRegistrationError = registrationState.stage === RegistrationStage.REGISTRATION_ERROR;
  const isTaken = registrationState.stage === RegistrationStage.EMAIL_TAKEN;
  const isVerificationReceived = registrationState.stage === RegistrationStage.VERIFICATION_RECIEVED;
  const isSetCulture = registrationState.stage === RegistrationStage.SET_CULTURE;
  const isRegistrationForm = registrationState.stage === RegistrationStage.REGISTRATION_FORM;
  const isRegistrationComplete = registrationState.stage === RegistrationStage.REGISTRATION_COMPLETE;

  const handleRegistrationStateChange = (state: RegistrationState) => setRegistrationState({ ...registrationState, ...state });

  return (
    <div>
      <Box sx={RegistrationContainerStyles}>
        {isEmailVerification && <EmailVerification email={registrationState.email} setRegistrationState={handleRegistrationStateChange} />}
        {isVerificationSent && <VerificationSent email={registrationState.email} />}
        {hasError && <ErrorMessage email={registrationState.email} setRegistrationState={handleRegistrationStateChange} />}
        {hasRegistrationError && <RegistrationErrorMessage setRegistrationState={handleRegistrationStateChange} />}
        {isTaken && <EmailTakenError setRegistrationState={handleRegistrationStateChange} />}
        {isVerificationReceived && <VerificationReceived setRegistrationState={handleRegistrationStateChange} />}
        {isSetCulture && <CultureSelect culture={culture} onSubmit={handleSaveCulture} />}
        {isRegistrationForm && <RegistrationFormComponent email={registrationState.email} otp={registrationState.otp} setRegistrationState={handleRegistrationStateChange} />}
        {isRegistrationComplete && <RegistrationComplete />}
      </Box>
    </div>
  );
}
