import { useEffect } from 'react';

const cssClass = 'let-body-fill-page';

export const useBodyClass = (className: string = cssClass) => {
	useEffect(() => {
		document.body.classList.add(className);
		return () => document.body.classList.remove(className);
	}, [className]);
};
