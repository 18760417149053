import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation, Trans } from 'react-i18next';
import { CustomerSupportNumber } from 'shared/components/customer-support/customer-support-number.component';
import { CustomerSupportEmail } from 'shared/components/customer-support/customer-support-email.component';
import { getRegion } from 'providers/region.provider';
import { Region } from 'shared/enums/region.enum';
import { useCountry } from 'app/hooks/store/use-country.hook';
import { HelpAndFaqButton } from 'features/registration';
import { enableOktaForUser } from 'utils/auth';
import * as Styles from 'features/registration/styles/registration-shared-styles';
import { Banner } from '@chr/eds-react';
import { RegistrationAction, useAction } from '@features/datadog';
import { ClipboardIcon } from 'assets/icons/ClipboardIcon';

export const RegistrationComplete = () => {
    const userCountry = useCountry();
    const { t } = useTranslation();
    const track = useAction();

    enableOktaForUser();
    track(RegistrationAction.Complete);

    return (
        <>
            <HelpAndFaqButton />
            <Box sx={Styles.ContainerStyles}>
                <Box sx={Styles.IconContainer}>
                    <ClipboardIcon height={130} width={90} data-testid="clip-board-icon" />
                </Box>
                <Box sx={Styles.LeftAlignedComponentBody}>
                    <Typography variant="h1" sx={Styles.HeaderContainerStyles}>
                        {t('REGISTRATION_ALMOST_COMPLETE')}
                    </Typography>
                    <Banner
                      severity='info'
                      title={t('YOUR_ACCOUNT_NEEDS_TO_BE_ACTIVATED')}
                    />
                    <Box sx={Styles.BodyContent}>
                        <Typography variant="body1">
                          {t('IF_YOU_HAVE_REGISTERED_AS_A_DRIVER')}
                        </Typography>
                    </Box>
                    <Typography variant="body1" sx={Styles.BodyContent}>
                        <Box component='span'>{t('FOR_ALL_OTHER_POSITIONS')}</Box>
                        <Box component='span' sx={{fontWeight: 'bold'}}>{t('THE_PRIMARY_CONTACT')}</Box>
                        <Box component='span'>{t('ACTIVATION_MAY_TAKE')} </Box>
                    </Typography>
                    <Typography variant="body1" sx={Styles.BodyContent}>
                        {t('YOU_WILL_RECEIVE_A_WELCOME_EMAIL')}
                    </Typography>
                    <Box>
                        <Typography variant="h3">{t('QUESTIONS')}</Typography>
                        <Typography variant="body1">
                            <Trans i18nKey="PLEASE_CALL_CARRIER_SERVICES_0" components={[<CustomerSupportNumber country={userCountry} />]} />
                        </Typography>
                        {userCountry && getRegion(userCountry) === Region.EUROPE && (
                            <Typography variant="body1">
                                <CustomerSupportEmail />
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );
};
