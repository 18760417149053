import { useState, useEffect } from 'react';
import { RegistrationState, RegistrationStage } from 'features/registration';

export const useRegistrationState = (): [RegistrationState, (state: Partial<RegistrationState>) => void] => {
	const [registrationState, updateRegistrationState] = useState<RegistrationState>(() => {
		const searchParams = new URLSearchParams(window.location.search);
		return {
			stage: (searchParams.get('stage') as RegistrationStage) || RegistrationStage.EMAIL_VERIFICATION,
			otp: searchParams.get('otp') || '',
			email: searchParams.get('email') || '',
		};
	});

	useEffect(() => {
		// Pull queryParams from current url then process ours
		const searchParams = new URLSearchParams(window?.location?.search);

		// find our specific params and set them
		Object.entries(registrationState)
			.filter(value => !!value)
			.forEach(([key, value]: any) => searchParams.set(key, value));

		window.history.replaceState(null, '', `${window.location.pathname}?${searchParams.toString()}`);
	}, [registrationState]);

	return [registrationState, (state: Partial<RegistrationState>) => updateRegistrationState(prev => ({ ...prev, ...state }))];
};
