import { useCallback, useEffect, useState } from 'react';
import { Container } from 'typedi';
import { TextInput } from '@chr/eds-react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { emailPattern } from 'app/forms/control/form-validators';
import { IdentityRepository } from 'app/repositories/identity.repository';
import { AjaxError } from 'rxjs/internal-compatibility';
import { LoadingButton } from '@features/okta/components/Buttons';
import {
  ContainerStyles,
  InputStyles,
  HeaderContainerStyles,
  SubHeaderContainerStyles,
  HelpAndFaqButton,
  RegistrationState,
  RegistrationStage,
} from 'features/registration';
import { useTranslation } from 'react-i18next';
import { useAction, RegistrationAction, MigrationAction } from '@features/datadog';
import { TurnstileCaptchaComponent } from '@shared/components/turnstile-captcha/turnstile-captcha.component';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CaptchaStorageKeys } from '@shared/components/turnstile-captcha/types';

export interface EmailVerificationProps {
  email: string;
  setRegistrationState: (state: RegistrationState) => void;
}

export enum EmailVerificationErrors {
  BLOCKED_CHR_DOMAIN = 'Email address is a blocked CHR domain.',
  EMAIL_ALREADY_IN_USE = 'Email address already in use.',
}

export const EmailVerification = ({ email, setRegistrationState }: EmailVerificationProps) => {
  const [emailError, setEmailError] = useState<string>(null);
  const [loading, setIsLoading] = useState(false);
  const [didUpdateEmail, setDidUpdateEmail] = useState(false);
  const identityRepo = Container.get(IdentityRepository);
  const track = useAction();
  const { t } = useTranslation();
  const [resetTracker, setResetTracker] = useState({ value: 0, reset: false });
  const { isIdentityUserCaptchaTokenEnabled } = useFlags();
  const isEphemeral = window.location.host.includes('.chr8s.io');
  const isEnabled = !isEphemeral && isIdentityUserCaptchaTokenEnabled;
  
  /**
   *
   *  @fires track('EV_VERIFICATION_EMAIL_SENT')
   */
  const onValidationSuccess = () => {
    // @dd:auth

    // if validation succeeds then send the actual email
    identityRepo
      .sendNewUserEmailVerification(email)
      .then(() => setRegistrationState({ stage: RegistrationStage.VERIFICATION_SENT }))
      .catch(() => setRegistrationState({ stage: RegistrationStage.ERROR }))
  };

  /**
   *
   * @param error
   *
   * @fires track('EV_VERIFICATION_DNF')
   */
  const onValidationError = (error: AjaxError) => {
    if (error?.status === 400 && error?.response[0]?.errorMessage === EmailVerificationErrors.BLOCKED_CHR_DOMAIN) {
      isEnabled && setResetTracker((s) => ({ ...s, value: Date.now() }));
      setEmailError(t('UNSUPPORTED_CHR_DOMAIN'));
    } else if (error?.status === 400 && error?.response[0]?.errorMessage === EmailVerificationErrors.EMAIL_ALREADY_IN_USE) {
      setRegistrationState({ stage: RegistrationStage.EMAIL_TAKEN });
      track(RegistrationAction.EmailTaken);
    } else {
      setRegistrationState({ stage: RegistrationStage.ERROR });
    }
    // @dd:auth
    track(RegistrationAction.EmailVerificationFailed);
  };

  /**
   *
   * @param e
   * @returns
   *
   * @fires track('EV_CLICKED')
   * @fires track('EV_USE_EXISTING_EMAIL'|'EV_USE_NEW_EMAIL')
   *
   */
  const handleSubmission = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!emailPattern.test(email)) {
      setEmailError(t('PLEASE_INPUT_A_VALID_EMAIL_ADDRESS'));
      return;
    }

    emailError && setEmailError(null);
    setIsLoading(true);

    if (!didUpdateEmail) {
      // @dd:auth
      track(MigrationAction.UseExistingEmail);
    }

    identityRepo
      .validateEmail(email)
      .then(() => onValidationSuccess())
      .catch(error => onValidationError(error));
  };

  /**
   * Update Email
   * @param e
   *
   *
   * @fires track('EV_USE_NEW_EMAIL')
   */
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!didUpdateEmail) {
      setDidUpdateEmail(true);
      track(MigrationAction.ChangedPreferredEmail);
    }

    setRegistrationState({ email: e.target.value });
    setEmailError(null);
  };

  const handleReset = useCallback(() => {
    setResetTracker((s) => s.value > 0 ? ({ ...s, reset: true }) : s);
  }, []);

  useEffect(() => {
    if (!isEnabled || (resetTracker.reset && emailError)) {
      setIsLoading(false);
      isEnabled && setResetTracker((s) => ({ ...s, reset: false }));
    }
  }, [isEnabled, emailError, resetTracker.reset])

  return (
    <form onSubmit={handleSubmission}>
      <HelpAndFaqButton />
      <Box sx={ContainerStyles}>
        <Box sx={HeaderContainerStyles}>
          <Typography variant="h1">{t('REGISTRATION_ENTER_EMAIL')}</Typography>
        </Box>
        <Box sx={SubHeaderContainerStyles}>
          <Typography variant="h4">{t('WE_WILL_SEND_YOU_AN_EMAIL')}</Typography>
        </Box>
        <Box sx={InputStyles}>
          <TextInput
            label={t('EMAIL_ADDRESS')}
            value={email}
            onChange={handleEmailChange}
            autoFocus
            helperText={loading ? t('EXAMPLE_EMAIL') : emailError ? emailError : t('EXAMPLE_EMAIL')}
            error={loading ? false : !!emailError}
          />
        </Box>
        <LoadingButton loading={loading} textResourceKey="SUBMIT" handleClick={handleSubmission} />
      </Box>
      <TurnstileCaptchaComponent
        resetTracker={resetTracker.value}
        onSuccessCallback={handleReset}
        isEnabled={isEnabled}
        captchaKey={CaptchaStorageKeys.emailValidation}
      />
      <TurnstileCaptchaComponent
        isEnabled={isEnabled}
        captchaKey={CaptchaStorageKeys.emailVerification}
      />
    </form>
  );
};
