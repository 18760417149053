import IndexedDB from 'lib/indexDatabase';
import { Cookies } from '@app/globals/constants';
import { Cookie } from 'ng2-cookies';

// Used to determine if auth should be JWT
export const isOkta = async (): Promise<boolean> => {
  try {
    const hasIndexedDb = (await IndexedDB.getData(Cookies.moAuth)) || false;
    const hasLocalStorage = JSON.parse(localStorage.getItem(Cookies.moAuth)) != null;
    const hasCookie = Cookie.get(Cookies.moAuth) != '';

    return hasIndexedDb || hasLocalStorage || hasCookie || false;
  } catch (err) {
    throw new Error(err);
  }
};

// Execute to finalize the migration for a user
export const enableOktaForUser = () => {
  try {
    IndexedDB.addData(Cookies.moAuth, true);
    localStorage.setItem(Cookies.moAuth, 'true');
    Cookie.set(Cookies.moAuth, Cookies.moAuth, 525600);
  } catch (err) {
    console.error(`Unable to enableOktaForUser`);
    console.error(err);
  }
};

export const disableOktaForUser = () => {
  try {
    IndexedDB.deleteData(Cookies.moAuth);
    localStorage.removeItem(Cookies.moAuth);
    Cookie.delete(Cookies.moAuth);
  } catch (err) {
    console.error(`Unable to disableOktaForUser`);
    console.error(err);
  }
};