import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { EdsStyleErrorMessage } from 'features/registration';

import * as Styles from 'features/registration/styles/form-styles';

export enum CarrierCodePositionValues {
  OWNER_OPERATOR = 'Owner-Operator',
  MANAGER = 'Manager',
  DISPATCHER = 'DISP',
  DRIVER_LCASE = 'DRVR',
  ACCOUNTS_RECEIVABLE = 'AR',
}

export enum VendorCodePositionValues {
  ACCOUNTS_RECEIVABLE = 'AR'
}
export interface PositionRadioGroupProps {
  value: CarrierCodePositionValues;
  handleChange: any;
  error: string;
  isTCode: boolean;
}

export interface PositionRadioButtonProps {
  value: string;
  labelResource: keyof IResources;
  descriptionResource: keyof IResources;
  isChecked: boolean;
  handleChange: any;
  error: string;
}

const PositionRadioButton = ({ value, labelResource, descriptionResource, isChecked, handleChange, error }: PositionRadioButtonProps) => {
    const { t } = useTranslation();

    return (
        <FormControlLabel
            value={value}
            onChange={handleChange}
            checked={isChecked}
            control={<Radio sx={Styles.RadioInput} />}
            sx={error ? Styles.ErroredRadioButton : isChecked ? Styles.SelectedRadioButton : Styles.RadioButton}
            label={
                <div className="label-container">
                    <Typography variant="subtitle1" sx={isChecked ? Styles.SelectedRadioHeader : {}}>
                        {t(labelResource)}
                    </Typography>
                    <Typography variant="body2">
                        {t(descriptionResource)}
                    </Typography>
                </div>
            }
        />
    );  
};

export const PositionRadioGroup = ({ value, handleChange, error, isTCode }: PositionRadioGroupProps) => {
  const positionValues = isTCode ? CarrierCodePositionValues : VendorCodePositionValues;
  return (
    <>
      {Object.entries(positionValues).map(([key, position]) => (
        <PositionRadioButton
          key={position}
          value={position}
          labelResource={key as keyof IResources}
          descriptionResource={`${key}_DESCRIPTION` as keyof IResources}
          isChecked={value === position}
          handleChange={handleChange}
          error={error}
        />
      ))}
      <Box sx={Styles.PositionErrorBox}>
        {error && <EdsStyleErrorMessage errorMessage={error} />}
      </Box>
    </>
  )
};
