import { Switch, Route } from "react-router";
import { AppRoute } from "@app/routesEnum";
import { Pages } from "@pages/pages";
import { SiteSection } from "@shared/components/analytics/site-section.component";
import { UnauthenticatedRoute } from "@shared/components/route/unauthenticated-route.component";
import { LazyRegistrationPageV2 } from 'features/registration/index';
import { useFlags } from 'launchdarkly-react-client-sdk';

export default function RegistrationRoutes() {
    const { newUserRegistrationV2 } = useFlags();

    // check for newUserRegistration query param, if not there then default to flag value
    const queryParams = new URLSearchParams(window.location.search);
    const newUserRegistrationParam = queryParams.get('newUserRegistration');
    const isNewUserRegistrationV2 = () => {
        switch (newUserRegistrationParam) {
            case 'v1':
                return false;
            case 'v2':
                return true;
            default:
                return newUserRegistrationV2;
        }
    }

    return (
        <>
            {isNewUserRegistrationV2() &&
                <>
                    <SiteSection name="Registration">
                        <UnauthenticatedRoute path={AppRoute.REGISTRATION}>
                            <Switch>
                                <Route path={AppRoute.REGISTRATION} exact component={LazyRegistrationPageV2} />
                            </Switch>
                        </UnauthenticatedRoute>
                    </SiteSection>
                </>
            }
            {!isNewUserRegistrationV2() &&
                <SiteSection name="Registration">
                    <UnauthenticatedRoute path={AppRoute.SHORT_REGISTRATION}>
                        <Switch>
                            <Route path={AppRoute.SHORT_REGISTRATION} exact component={Pages.ShortRegistration} />
                            <Route path={AppRoute.SHORT_REGISTRATION_SUCCESS} component={Pages.ShortRegistrationSuccess} />
                            <Route path={AppRoute.SHORT_REGISTRATION_ERROR} component={Pages.ShortRegistrationError} />
                        </Switch>
                    </UnauthenticatedRoute>
                    <UnauthenticatedRoute path={AppRoute.REGISTRATION}>
                        <Switch>
                            <Route path={AppRoute.REGISTRATION} exact component={Pages.Registration} />
                            <Route path={AppRoute.REGISTRATION_SUCCESS} component={Pages.RegistrationSuccess} />
                        </Switch>
                    </UnauthenticatedRoute>
                </SiteSection>
            }
            
        </>
    )
}
