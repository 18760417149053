import { ICarrierSvg } from '.';

export const ClipboardIcon = ({ className, alt, height = 130, width = 94, ...rest }: ICarrierSvg) => {
 return (
  <svg {...{ className, alt, width, height, ...rest }} viewBox="0 0 94 130" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M83.28 9.25122L2.72 8.86133C2.18957 8.86133 1.68086 9.07195 1.30579 9.44702C0.930713 9.82209 0.720001 10.3309 0.720001 10.8613V99.5413C0.720001 100.072 0.930713 100.58 1.30579 100.955C1.68086 101.33 2.18957 101.541 2.72 101.541H75.18C77.8588 101.536 80.4262 100.469 82.3195 98.5737C84.2127 96.6786 85.2774 94.1099 85.28 91.4312V11.2512C85.28 10.7208 85.0693 10.212 84.6942 9.83691C84.3192 9.46184 83.8104 9.25122 83.28 9.25122Z" fill="#AAD7F3" />
	<path d="M72.8206 17.5215H12.6507C11.5461 17.5215 10.6507 18.4169 10.6507 19.5215V88.1714C10.6507 89.276 11.5461 90.1714 12.6507 90.1714H72.8206C73.9252 90.1714 74.8206 89.276 74.8206 88.1714V19.5215C74.8206 18.4169 73.9252 17.5215 72.8206 17.5215Z" fill="white" />
	<path d="M62.1504 63.1909H38.3604C37.777 63.1909 37.2174 62.9592 36.8048 62.5466C36.3922 62.1341 36.1604 61.5744 36.1604 60.991C36.1604 60.4075 36.3922 59.8479 36.8048 59.4353C37.2174 59.0227 37.777 58.791 38.3604 58.791H62.1504C62.7339 58.791 63.2935 59.0227 63.7061 59.4353C64.1187 59.8479 64.3504 60.4075 64.3504 60.991C64.3504 61.5744 64.1187 62.1341 63.7061 62.5466C63.2935 62.9592 62.7339 63.1909 62.1504 63.1909Z" fill="#AAD7F3" />
	<path d="M62.1504 49.3513H38.3604C37.777 49.3513 37.2174 49.1194 36.8048 48.7068C36.3922 48.2942 36.1604 47.7346 36.1604 47.1511C36.1604 46.5676 36.3922 46.0083 36.8048 45.5957C37.2174 45.1831 37.777 44.9512 38.3604 44.9512H62.1504C62.7339 44.9512 63.2935 45.1831 63.7061 45.5957C64.1187 46.0083 64.3504 46.5676 64.3504 47.1511C64.3504 47.7346 64.1187 48.2942 63.7061 48.7068C63.2935 49.1194 62.7339 49.3513 62.1504 49.3513Z" fill="#AAD7F3" />
	<path d="M62.1502 76.1625H38.3602C38.0595 76.1828 37.7578 76.1413 37.4739 76.0402C37.19 75.939 36.9299 75.7806 36.7097 75.5748C36.4895 75.369 36.314 75.1201 36.1939 74.8436C36.0739 74.5671 36.012 74.269 36.012 73.9676C36.012 73.6662 36.0739 73.3679 36.1939 73.0914C36.314 72.815 36.4895 72.5663 36.7097 72.3605C36.9299 72.1547 37.19 71.9962 37.4739 71.8951C37.7578 71.794 38.0595 71.7523 38.3602 71.7726H62.1502C62.4509 71.7523 62.7526 71.794 63.0366 71.8951C63.3205 71.9962 63.5806 72.1547 63.8008 72.3605C64.021 72.5663 64.1965 72.815 64.3165 73.0914C64.4365 73.3679 64.4985 73.6662 64.4985 73.9676C64.4985 74.269 64.4365 74.5671 64.3165 74.8436C64.1965 75.1201 64.021 75.369 63.8008 75.5748C63.5806 75.7806 63.3205 75.939 63.0366 76.0402C62.7526 76.1413 62.4509 76.1828 62.1502 76.1625Z" fill="#AAD7F3" />
	<path d="M23.9799 51.1213C23.416 51.1238 22.8737 50.9046 22.4698 50.5111L19.9698 48.131C19.6102 47.7197 19.4165 47.1895 19.4264 46.6432C19.4363 46.097 19.6491 45.5739 20.0234 45.1759C20.3977 44.7779 20.9067 44.5333 21.4514 44.4899C21.996 44.4465 22.5373 44.6073 22.9698 44.9411L23.6699 45.6112L27.5298 40.391C27.8935 39.9646 28.4056 39.6924 28.9626 39.6293C29.5195 39.5662 30.0795 39.7169 30.5294 40.0512C30.9793 40.3854 31.2854 40.8782 31.3857 41.4296C31.4861 41.981 31.3732 42.5499 31.0698 43.0212L25.7198 50.2311C25.5357 50.4833 25.2992 50.6925 25.0264 50.8444C24.7536 50.9963 24.4512 51.0875 24.1399 51.1112L23.9799 51.1213Z" fill="#AAD7F3" />
	<path d="M23.9801 64.6413C23.4163 64.6438 22.874 64.4249 22.4701 64.0314L19.9701 61.6513C19.7221 61.4655 19.5161 61.2294 19.3657 60.9584C19.2153 60.6875 19.1238 60.388 19.0972 60.0793C19.0707 59.7705 19.1097 59.4596 19.2116 59.1669C19.3136 58.8743 19.4762 58.6064 19.6889 58.381C19.9015 58.1556 20.1594 57.9776 20.4456 57.8588C20.7318 57.74 21.04 57.683 21.3497 57.6916C21.6595 57.7001 21.964 57.774 22.2433 57.9084C22.5225 58.0428 22.7702 58.2346 22.9701 58.4714L23.6701 59.1313L27.5301 53.9213C27.7021 53.6889 27.9183 53.4926 28.1662 53.3437C28.4141 53.1948 28.6888 53.0961 28.9748 53.0534C29.2608 53.0107 29.5525 53.0249 29.833 53.0949C30.1136 53.1649 30.3777 53.2893 30.6101 53.4613C30.8426 53.6334 31.0388 53.8494 31.1877 54.0973C31.3367 54.3452 31.4353 54.6202 31.478 54.9062C31.5207 55.1922 31.5067 55.4837 31.4367 55.7643C31.3667 56.0449 31.2421 56.309 31.0701 56.5414L25.7201 63.7514C25.536 64.0035 25.2994 64.2127 25.0266 64.3647C24.7539 64.5166 24.4514 64.6075 24.1401 64.6313L23.9801 64.6413Z" fill="#AAD7F3" />
	<path d="M63.5003 6.67236C63.413 6.52123 63.2677 6.39558 63.1168 6.30786C62.9659 6.22015 62.7947 6.17342 62.6202 6.17236H51.1402C50.1742 4.43963 48.763 2.99647 47.0524 1.9917C45.3418 0.986928 43.394 0.457031 41.4102 0.457031C39.4264 0.457031 37.4785 0.986928 35.768 1.9917C34.0574 2.99647 32.6462 4.43963 31.6802 6.17236H21.4202C21.155 6.17236 20.9006 6.2778 20.7131 6.46533C20.5256 6.65287 20.4202 6.90715 20.4202 7.17236V21.4724C20.4202 21.7376 20.5256 21.9919 20.7131 22.1794C20.9006 22.367 21.155 22.4724 21.4202 22.4724H62.6202C62.7947 22.4714 62.9659 22.4246 63.1168 22.3369C63.2677 22.2492 63.3929 22.1236 63.4802 21.9724L63.5102 7.67236C63.5935 7.51772 63.6345 7.344 63.6292 7.16846C63.624 6.99291 63.5926 6.82173 63.5003 6.67236Z" fill="#5CB3E4" />
  </svg>
 );
};
